import { Playground, Props } from 'docz';
import { Hint } from '@nejcm/docz-theme-extended';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import * as React from 'react';
export default {
  Playground,
  Props,
  Hint,
  useIntl,
  Link,
  FormattedMessage,
  React
};