// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---docs-app-console-mdx": () => import("./../docs/App/console.mdx" /* webpackChunkName: "component---docs-app-console-mdx" */),
  "component---docs-app-definitions-mdx": () => import("./../docs/App/definitions.mdx" /* webpackChunkName: "component---docs-app-definitions-mdx" */),
  "component---docs-app-settings-mdx": () => import("./../docs/App/settings.mdx" /* webpackChunkName: "component---docs-app-settings-mdx" */),
  "component---docs-getstarted-mdx": () => import("./../docs/getstarted.mdx" /* webpackChunkName: "component---docs-getstarted-mdx" */),
  "component---docs-index-mdx": () => import("./../docs/index.mdx" /* webpackChunkName: "component---docs-index-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-service-js": () => import("./../src/pages/terms-service.js" /* webpackChunkName: "component---src-pages-terms-service-js" */)
}

